<template>
    <div class="request-form-wrap">
        <form class="form" @submit.prevent="submit" id="wrapper">
            <div v-if="!$store.state.modalFormSuccess">
                <fieldsrequestform
                        :items="form['requested-quote']"
                        @returnStatusToParent="formStatus => formIsValid=formIsValid&&formStatus"
                        :nameproductprops="nameproduct"></fieldsrequestform>

                <div class="button-wrap">
                    <button type="submit" class="btn">Request</button>
                </div>

                <div class="row" v-if="$store.state.submitStatus === 'ERROR' || $store.state.submitStatus === 'OK' || $store.state.submitStatus === 'PENDING'">
                    <div class="col wide">
                        <div class="info-text">
                            <div class="error-wrap" v-if="$store.state.submitStatus === 'ERROR'">
                                <div class="icon-cross close" @click="$store.state.submitStatus = null"></div>
                                Some required fields are missing. Please fix them.
                            </div>
                            <div class="typo__p" v-if="$store.state.submitStatus === 'PENDING'">Sending...</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="Form-Modal-wrap Info-Modal"
                 v-if="$store.state.modalFormSuccess"
                 :class="{'active': $store.state.modalFormSuccess}">
                <div class="overflow" @click="closeModal()"></div>

                <div class="wrap">
                    <div class="info-message">
                        <div class="icon icon-success"></div>
                        <div class="title">Success</div>
                        <div class="text">Thank you for contacting us! We will contact you shortly!</div>
                        <div class="btn" @click="closeModal()">Ok</div>
                    </div>
                </div>
            </div>

            <vue-recaptcha :sitekey="recapthcakey"
                           data-badge="inline"
                           ref="recaptcha"
                           size="invisible"
                           @verify="onVerify"></vue-recaptcha>
        </form>
    </div>
</template>

<script>
    import fieldsrequestform from "./fieldsrequestform.vue";
    import Vue from 'vue'
    import Vuelidate from 'vuelidate'
    import { required, email } from 'vuelidate/lib/validators'
    import VueRecaptcha from 'vue-recaptcha';
    import VueScrollTo from 'vue-scrollto'
    import VueMask from 'v-mask'

    Vue.use(VueMask);
    Vue.use(Vuelidate);
    Vue.use(VueScrollTo);

    export default {
        name: 'request',
        components: {
            'vue-recaptcha': VueRecaptcha,
            fieldsrequestform
        },
        props: [
            'recapthcakey',
            'form',
            'actionprops',
            'career',
            'title',
            'nameproduct',
        ],
        data() {
            return {
                captcha: '',
                formIsValid: false,
                fileName: '',
                file: [],
            }
        },
        validations() {
        },
        methods: {
            onFileChange(el) {
                // eslint-disable-next-line no-unused-vars
                const files = el.target.files || el.dataTransfer.files;
                // eslint-disable-next-line prefer-destructuring
                this.fileName = document.getElementById('upload').files[0].name;
                this.file.push(document.getElementById('upload').files[0]);
            },
            fileDel() {
                this.$nextTick(() => {
                    this.$refs['fileupload'].value = ''
                });
                this.fileName = '';
                this.file = [];
            },
            changeFile() {
            },
            submit() {
                this.formIsValid = true;
                this.$eventPool.$emit("touchChildForm");

                this.$nextTick(() => {
                    if (this.formIsValid) {
                        this.$refs.recaptcha.execute();
                    } else {
                        this.$store.state.submitStatus = 'ERROR';
                        VueScrollTo.scrollTo('.error', 300, {container: '.Form-Modal-wrap',});
                    }
                })
            },
            onVerify: function (response) {
                this.$store.state.submitStatus = 'PENDING';

                let formData = new FormData();
                formData.append('g-recaptcha-response', response);
                formData.append('form_type', 'request-quote');
                formData.append('Equipment', this.nameproduct);
                // formData.append('career', '#' + this.career.id + ' - ' + this.career.title);

                const currentForm = this.$store.state.currentForm;
                Object.keys(currentForm).forEach((item) => {
                    if (typeof currentForm[item] === 'object') {
                        formData.append(item, JSON.stringify(currentForm[item]))
                    } else {
                        formData.append(item, currentForm[item])
                    }
                });

                axios.post(this.actionprops, formData)
                    .then(response => {
                        console.log('message', response);
                        console.log(this.$store.state.currentForm);
                        if (response.data.result === 'ok') {
                            this.$store.state.submitStatus = 'OK';
                            this.$store.state.modalFormSuccess = true;
                            this.$store.state.currentForm = {};
                            
                              console.log('gtag product')
                            gtag('event', 'submit', {
                                'event_category': 'form',
                                'event_label': 'product-qoute',
                                'value': 1
                                });
                        }

                        if (response.data.result === 'error') {
                            this.$store.state.submitStatus = 'ERROR';
                            this.$store.state.modalFormSuccess = null;
                        }

                        this.$refs.recaptcha.reset();
                    })
                    .catch(error => {
                        console.log(error)
                        this.$refs.recaptcha.reset();
                    });
            },
            status(validation) {
                return {
                    error: validation.$error,
                    dirty: validation.$dirty
                }
            },
            closeModal() {
                this.$store.state.modalId = null;
                this.$store.state.modalFormSuccess = false;
                this.$store.state.noscrollActive = false;
            },
            closeModaljoin() {
                this.$store.state.joinModalAtive = false;
                this.$store.state.noscrollActive = false;
            },
        },
    }
</script>

<style scoped lang="scss">
    @import './resources/sass/var.scss';

    .form .info-text {
        margin-top: 0;
    }

</style>
