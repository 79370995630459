<template lang="pug">
    .list--group
        .list--item(v-for="(itemRow, index) in items"
            :class="{'image-item': itemRow.type === 'image'}")

            .field(v-if="itemRow.type === 'select'"
                :class="{'icon-wrap': itemRow.icon}")
                .label(v-if="itemRow.title") {{ itemRow.title }}

                .input-wrap(:class="status($v.field)")
                    .icon(v-if="itemRow.icon")
                        img(:src="itemRow.icon.src")
                    v-select(
                        :options="itemRow.options"
                        @input="inputField => change(itemRow, inputField)"
                        @change="$v.field.$touch()"
                        :class="status($v.field)"
                        :placeholder="itemRow.placeholder"
                        autocomplete="disabled")

            fieldsBlockPalletSelect(v-if="itemRow.children && itemRow.children.length > 0"
                :items="itemRow.children"
                @returnStatusToParent="formStatus =>$emit('returnStatusToParent', formStatus)")


</template>

<script>
    import Vue from 'vue'
    import Vuex from 'vuex'
    const mapState = Vuex.mapState
    import Vuelidate from 'vuelidate'
    import { required, email, minLength } from 'vuelidate/lib/validators'
    import VueScrollTo from 'vue-scrollto'
    import VueMask from 'v-mask'
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";
    Vue.component("v-select", vSelect);
    Vue.use(Vuelidate);
    Vue.use(VueScrollTo);
    Vue.use(VueMask);

    export default {
        name: "fieldsBlockPalletSelect",
        props: [
            'items',
            'inputFieldprops',
            'itemRowprops',
        ],
        data: () => {
            return {
                sectionName: '',
                field: '',
                nameField: '',
            }
        },
        watch: {
            "$v.$invalid": function() {
                this.$emit("returnStatusToParent", !this.$v.$invalid);
            }
        },
        methods: {
            change(item, inputField) {
                this.addNameField(item, inputField)
                this.$v.$touch()
            },
            status(validation) {
                return {
                    error: validation.$error,
                    dirty: validation.$dirty
                }
            },
            addNameField(item, inputField) {
                this.$store.commit('setFormField', { field: item.section + '.' + item['block-string-id'], value: inputField })
            },
        },
        validations() {
            let rules = {}

            if (this.fieldRequired) {
                rules = { ...rules, required }
            }

            if (this.fieldType === 'email') {
                rules = { ...rules, email }
            }

            if (this.fieldType === 'phone') {
                rules = { ...rules, minLength: minLength(12) }
            }

            return {
                field: rules
            }
        },
        computed: {
            inputField: {
                get: function() {
                    return this.field;
                },
                set: function(value) {
                    this.field = value;
                }
            },
            fieldType: function () {
                return this.items[0]['input-type'] || 'text'
            },
            fieldRequired: function () {
                if(this.items[0].required) {
                    return this.items[0].required
                }
            },
        },
        mounted() {
            this.addNameField(this.items[0], {})

            this.$eventPool.$on("touchChildForm", () => {
                this.$v.$touch()
            });
        },
        destroyed() {
            this.$eventPool.$off("touchChildForm", () => {})
        },
    }
</script>

<style lang="scss">
    @import './resources/sass/app.scss';

</style>
