/* eslint-disable no-param-reassign,no-cond-assign,no-return-assign */
/* global document */
import './bootstrap'

import Vue from 'vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import Vuex from 'vuex'

import * as Sentry from '@sentry/browser'

import { Vue as VueIntegration } from '@sentry/integrations'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import VueScrollTo from 'vue-scrollto'

// eslint-disable-next-line import/no-extraneous-dependencies
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'vue-select/dist/vue-select.css'

Vue.use(VueScrollTo)
Vue.component('v-select', vSelect)
Vue.use('vue-slick-carousel', VueSlickCarousel)
Vue.use(Vuex)

Vue.component('get-a-quote', require('./components/GetAQuote/GetAQuote.vue').default)
Vue.component('block-pallet', require('./components/GetAQuote/BlockPallet.vue').default)
Vue.component('fieldsBlockPallet', require('./components/GetAQuote/fieldsBlockPallet.vue').default)
Vue.component('contact-form', require('./components/contact/contact.vue').default)
Vue.component('vacancy-form', require('./components/vacancy/vacancy.vue').default)
Vue.component('fieldsvacancy', require('./components/vacancy/fieldsvacancy.vue').default)
Vue.component('request-form', require('./components/request/requestForm.vue').default)
Vue.component('fieldsrequestform', require('./components/request/fieldsrequestform.vue').default)

const sentryDSN = document.querySelector('meta[name="sentry-dsn"]')
    .getAttribute('content')

if (sentryDSN) {
    Sentry.init({
        dsn: sentryDSN,
        integrations: [
            new VueIntegration({
                Vue,
                attachProps: true,
                logErrors: true,
            }),
        ],
    })
}

function setObject(name, value, context) {
    const parts = name.split('.')
    const p = parts.pop()
    let i = 0; let
        j
    for (; context && (j = parts[i]); i += 1) {
        context = (j in context ? context[j] : context[j] = {})
    }
    return context && p ? (context[p] = value) : undefined // Object
}

Vue.prototype.$eventPool = new Vue()

// eslint-disable-next-line no-unused-vars
const store = () => new Vuex.Store({
    state: {
        modalId: null,
        modalFormSuccess: null,
        searchActive: false,
        joinModalAtive: false,
        submitStatus: null,
        currentForm: {},
        currentFormStatus: '',
        currentFormValidation: {},
        contactInformation: {},
        blockPallet: {},
        stringerPallet: {},
        contacts: {},
        sectionName: {},
        groupName: '',
        fieldGroupName: '',
    },
    mutations: {
        setFormField(state, payload) {
            const { currentForm } = state
            setObject(payload.field, payload.value, currentForm)

            state.currentForm = currentForm
        },
        formValidation(state, payload) {
            const { currentFormValidation } = state
            setObject(payload.field, payload.value, currentFormValidation)
            state.currentFormValidation = currentFormValidation
        },
    },
})

// eslint-disable-next-line no-unused-vars
const body = document.querySelector('body')

export default new Vue({
    el: '#app',
    store,
    components: {
        VueSlickCarousel,
    },
    data() {
        return {
            settingsSlider: {
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: false,
                dots: true,
                arrows: false,
                edgeFriction: 0.35,
                infinite: true,
                autoplay: null,
                adaptiveHeight: true,
                speed: 500,
            },
            settingsSliderMobile: {
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: false,
                dots: false,
                arrows: false,
                edgeFriction: 0.35,
                centerMode: false,
                infinite: false,
                autoplay: null,
                speed: 500,
                variableWidth: true,
            },
            activeIndex: -1,
            activeIndexModal: -1,
            selectActive: null,
            mobileActive: false,
            mobileMenuActive: false,
            noscrollActive: false,
        }
    },
    methods: {
        searchOpen() {
            this.$store.state.searchActive = true
            this.$nextTick(() => {
                this.$refs['input-search'].focus()
            })
        },
        searchClose() {
            this.$store.state.searchActive = false
        },
        searchClear() {
            this.$nextTick(() => {
                this.$refs['input-search'].value = ''
                this.$refs['input-search'].focus()
            })
        },

        filterItem(index) {
            if (this.activeIndex === index) {
                this.activeIndex = -1
            } else {
                this.activeIndex = index
            }
        },
        mobileMenu(index) {
            if (this.activeIndex === index) {
                this.activeIndex = -1
            } else {
                this.activeIndex = index
            }
        },
        closeCareersModal() {
            this.activeIndex = -1
            this.$store.state.noscrollActive = false
        },
        openModal(index) {
            this.activeIndexModal = index
            this.$store.state.noscrollActive = true
            this.$store.state.modalId = index.substr(1)
        },
        closeModal() {
            this.activeIndexModal = -1
            this.$store.state.noscrollActive = false
            this.$store.state.modalId = null
            this.$store.state.modalFormSuccess = false
        },
        openModaljoin() {
            this.$store.state.joinModalAtive = true
            this.$store.state.noscrollActive = true
        },
        openMenu() {
            this.mobileMenuActive = !this.mobileMenuActive
        },
        topScroll() {
            VueScrollTo.scrollTo('.header', 300)
        },
    },
    mounted() {
        this.mobileMenuActive = false

        if (document.documentElement.clientWidth < 769) {
            this.mobileActive = true
        } else {
            this.mobileActive = false
        }
    },
    updated() {
        // eslint-disable-next-line max-len
        if (this.$store.state.noscrollActive) {
            body.classList.add('noscroll')
        } else {
            body.classList.remove('noscroll')
        }
    },
})
