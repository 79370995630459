<template>
    <form class="form" @submit.prevent="submit">
        <div class="title-wrap" v-for="itemChildren in form"
             v-if="itemChildren.type === 'title'">
            <h3 class="title">{{ itemChildren.title }}</h3>
        </div>

        <fieldscontact :items="form" @returnStatusToParent="formStatus => formIsValid=formIsValid&&formStatus"></fieldscontact>

        <div class="Form-Modal-wrap Info-Modal"
             v-if="$store.state.modalFormSuccess"
             :class="{'active': $store.state.modalFormSuccess}">
            <div class="overflow" @click="closeModal()"></div>

            <div class="wrap">
                <div class="info-message">
                    <div class="icon icon-success2"></div>
                    <div class="title">Message<br> Sent successfully!</div>
                    <div class="text">Thank you for contacting us! We will contact you shortly!</div>
                    <div class="btn" @click="closeModal()">Ok</div>
                </div>
            </div>
        </div>

        <div class="row" v-if="$store.state.submitStatus === 'ERROR' || $store.state.submitStatus === 'OK' || $store.state.submitStatus === 'PENDING'">
            <div class="col wide">
                <div class="info-text">
                    <div class="error-wrap" v-if="$store.state.submitStatus === 'ERROR'">
                        <div class="icon-cross close" @click="$store.state.submitStatus = null"></div>
                        Some required fields are missing. Please fix them.
                    </div>
                    <div class="typo__p" v-if="$store.state.submitStatus === 'PENDING'">Sending...</div>
                </div>
            </div>
        </div>

        <vue-recaptcha :sitekey="recapthcakey"
                       data-badge="inline"
                       ref="recaptcha"
                       size="invisible"
                       @verify="onVerify"></vue-recaptcha>
    </form>
</template>

<script>
    import fieldscontact from "./fieldscontact.vue";
    import Vue from 'vue'
    import { mapState } from 'vuex'
    import Vuelidate from 'vuelidate'
    import { required, email } from 'vuelidate/lib/validators'
    import VueRecaptcha from 'vue-recaptcha';
    import VueScrollTo from 'vue-scrollto'
    import VueMask from 'v-mask'

    Vue.use(VueMask);
    Vue.use(Vuelidate);
    Vue.use(VueScrollTo);

    var body = document.querySelector('body');

    export default {
        name: 'Contact',
        components: {
            'vue-recaptcha': VueRecaptcha,
            fieldscontact
        },
        props: [
            'formtype',
            'recapthcakey',
            'form',
            'actionprops'
        ],
        data() {
            return {
                captcha: '',
                formIsValid: true,
            }
        },
        validations() {
        },
        methods: {
            submit() {
                this.formIsValid = true;
                this.$eventPool.$emit("touchChildForm");

                this.$nextTick(() => {
                    if (this.formIsValid) {
                        this.$refs.recaptcha.execute();
                    } else {
                        this.$store.state.submitStatus = 'ERROR';
                        setTimeout(() => {
                            VueScrollTo.scrollTo('.error', 300, {container: '.Form-Modal-wrap',});
                        }, 200);
                    }
                })
            },
            onVerify: function (response) {
                this.$store.state.submitStatus = 'PENDING';

                let formData = new FormData();
                formData.append('g-recaptcha-response', response);
                formData.append('form_type', this.$props.formtype);

                const currentForm = this.$store.state.currentForm;
                Object.keys(currentForm).forEach((item) => {
                    if (typeof currentForm[item] === 'object') {
                        formData.append(item, JSON.stringify(currentForm[item]))
                    } else {
                        formData.append(item, currentForm[item])
                    }
                });

                axios.post(this.actionprops, formData)
                    .then(response => {
                        console.log('message', response);
                     
                        if (response.data.result === 'ok') {
                            this.$store.state.submitStatus = 'OK';
                            this.$store.state.modalFormSuccess = true;
                            this.$store.state.currentForm = {};
                            if(this.$props.formtype == 'contact'){
                              console.log('gtag contact')
                             gtag('event', 'submit', {
                            'event_category': 'form',
                            'event_label': 'main-contact',
                            'value': 1
                            });
                            }
                           else if(this.$props.formtype == 'billing-question'){
                              console.log('gtag billing')
                          gtag('event', 'submit', {
                            'event_category': 'form',
                            'event_label': 'billing-question',
                            'value': 1
                            });
                            }
                        }

                        if (response.data.result === 'error') {
                            this.$store.state.submitStatus = 'ERROR';
                            this.$store.state.modalId = null;
                            this.$store.state.modalFormSuccess = null;
                        }

                        this.$refs.recaptcha.reset();
                    })
                    .catch(error => {
                        console.log(error);
                        this.$refs.recaptcha.reset();
                    });
            },
            closeModal() {
                this.$store.state.modalId = null;
                this.$store.state.modalFormSuccess = false;
                this.$store.state.noscrollActive = false;
            },
            status(validation) {
                return {
                    error: validation.$error,
                    dirty: validation.$dirty
                }
            },
        },
    }
</script>

<style scoped lang="scss">
    @import './resources/sass/var.scss';

    .form .info-text {
        margin-top: 0;
    }

</style>
