<template lang="pug">
    .field-wrap(:class="[{'field-row': typeChild === 'row'}, {'field-column': typeChild === 'column'}, {'field-section': typeChild === 'section'}]")
        .field-item(v-for="(itemRow, index) in items"
            :class="[{'image-item': itemRow.type === 'image'}, {'middle-column': itemRow['middle-column'] === true}]")

            .field(v-if="itemRow['input-type'] === 'product-name'")
                .label(v-if="itemRow.title") {{ itemRow.title }}

                .input-wrap
                    input(type='text'
                        :name='itemRow.placeholder'
                        @change="change(itemRow)"
                        :value="nameproductprops"
                        class="fill"
                        readonly).input
                    .placeholder {{ itemRow.placeholder }}

            .field(v-if="itemRow['input-type'] === 'text'"
                :class="{'icon-wrap': itemRow.icon}")
                .label(v-if="itemRow.title") {{ itemRow.title }}

                .input-wrap(:class="status($v.field)")
                    .icon(v-if="itemRow.icon")
                        img(:src="itemRow.icon.src")
                    input(type='text'
                        :name='itemRow.placeholder'
                        @change="change(itemRow)"
                        v-model="inputField"
                        :class="[status($v.field), {'fill': $v.field.$model}]").input
                    .placeholder {{ itemRow.placeholder }}
                        span(v-if="itemRow.required") *
                    .error-text This field is required

            .field(v-if="itemRow['input-type'] === 'email'"
                :class="{'icon-wrap': itemRow.icon}")
                .label(v-if="itemRow.title") {{ itemRow.title }}

                .input-wrap(:class="status($v.field)")
                    .icon(v-if="itemRow.icon")
                        img(:src="itemRow.icon.src")
                    input(type='text'
                        :name='itemRow.placeholder'
                        v-model="inputField"
                        @change="change(itemRow)"
                        :class="[status($v.field), {'fill': $v.field.$model}]").input
                    .placeholder {{ itemRow.placeholder }}
                        span(v-if="itemRow.required") *
                    .error-text This field is required

            .field(v-if="itemRow['input-type'] === 'phone'"
                :class="{'icon-wrap': itemRow.icon}")
                .label(v-if="itemRow.title") {{ itemRow.title }}

                .input-wrap(:class="status($v.field)")
                    .icon(v-if="itemRow.icon")
                        img(:src="itemRow.icon.src")
                    input(type='text'
                        :name='itemRow.placeholder'
                        v-model="inputField"
                        @change="change(itemRow)"
                        v-mask="'### ### ####'"
                        @input="$v.field.$touch()"
                        :class="[status($v.field), {'fill': $v.field.$model}]").input
                    .placeholder {{ itemRow.placeholder }}
                        span(v-if="itemRow.required") *
                    .error-text This field is required

            .field(v-if="itemRow.type === 'select'"
                :class="{'icon-wrap': itemRow.icon}")
                .label(v-if="itemRow.title") {{ itemRow.title }}

                .input-wrap(:class="status($v.field)")
                    .icon(v-if="itemRow.icon")
                        img(:src="itemRow.icon.src")
                    v-select(v-model="inputField"
                        @input="change(itemRow)"
                        @change="$v.field.$touch()"
                        :class="[status($v.field), {'fill': $v.field.$model}]"
                        :options="itemRow.options")
                    .placeholder {{ itemRow.placeholder }}
                        span(v-if="itemRow.required") *
                    .error-text This field is required

            .field(v-if="itemRow['input-type'] === 'date'"
                :class="{'icon-wrap': itemRow.icon}")
                .label(v-if="itemRow.title") {{ itemRow.title }}

                .date-wrap(:class="[status($v.field), {'disable': fieldCheck.immediately === true}]")
                    .input-wrap
                        .icon(v-if="itemRow.icon")
                            img(:src="itemRow.icon.src")
                        date-picker(
                            v-model="inputField"
                            value-type="format"
                            format="MM/DD/YYYY"
                            @input="change(itemRow)"
                            @change="$v.field.$touch()"
                            :class="[status($v.field), {'fill': $v.field.$model && $v.field.$model !== ''}]")
                        .placeholder {{ itemRow.placeholder }}
                            span(v-if="itemRow.required") *
                    label(:for="'immediately-' + itemRow.id").button-wrap
                        input(type="checkbox"
                            v-model="fieldCheck.immediately"
                            @input="change(itemRow)"
                            @change="$v.field.$touch()"
                            @click="immediately()"
                            :id="'immediately-' + itemRow.id")
                        .button immediately!

            .field(v-if="itemRow['input-type'] === 'number'"
                :class="{'icon-wrap': itemRow.icon}")
                .label(v-if="itemRow.title") {{ itemRow.title }}

                .input-wrap(:class="status($v.field)")
                    .icon(v-if="itemRow.icon")
                        img(:src="itemRow.icon.src")
                    input(type='number'
                        :name='itemRow.placeholder'
                        @change="change(itemRow)"
                        :class="[status($v.field), {'fill': $v.field.$model}]"
                        v-model="inputField").input
                    .placeholder {{ itemRow.placeholder }}
                        span(v-if="itemRow.required") *
                    .error-text This field is required

            .field(v-if="itemRow.type === 'textarea'"
                :class="{'icon-textarea-wrap': itemRow.icon}")
                .label(v-if="itemRow.title") {{ itemRow.title }}

                .input-wrap.textarea-wrap(:class="status($v.field)")
                    .icon(v-if="itemRow.icon")
                        img(:src="itemRow.icon.src")
                    textarea(:name='itemRow.placeholder'
                        @change="change(itemRow)"
                        :class="[status($v.field), {'fill': $v.field.$model}]"
                        v-model="inputField").textarea
                    .placeholder {{ itemRow.placeholder }}
                        span(v-if="itemRow.required") *
                    .error-text This field is required

            .field(v-if="itemRow.type === 'button'")
                button(type="submit").btn {{ itemRow.title }}

            fieldsrequestform(v-if="itemRow.children && itemRow.children.length > 0"
                :typeChild="itemRow.type"
                :items="itemRow.children"
                :nameproductprops="nameproductprops"
                @returnStatusToParent="formStatus =>$emit('returnStatusToParent', formStatus)")


</template>

<script>
    import Vue from 'vue'
    import Vuex from 'vuex'
    const mapState = Vuex.mapState
    import Vuelidate from 'vuelidate'
    import { required, email, minLength } from 'vuelidate/lib/validators'
    import VueScrollTo from 'vue-scrollto'
    import VueMask from 'v-mask'
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";
    Vue.component("v-select", vSelect);
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    Vue.use(Vuelidate);
    Vue.use(VueScrollTo);

    Vue.use(VueMask);

    export default {
        name: "fieldsRequest",
        props: [
            'items',
            'typeChild',
            'array',
            'nameproductprops',
        ],
        components: {
            DatePicker
        },
        data: () => {
            return {
                field: '',
                fieldCheck: {
                    date: '',
                    immediately: false,
                },
            }
        },
        watch: {
            "$v.$invalid": function() {
                this.$emit("returnStatusToParent", !this.$v.$invalid);
            }
        },
        methods: {
            immediately() {
                if(this.fieldCheck.immediately !== true) {
                    this.field = 'immediately!';
                } else {
                    this.field = ''
                }
            },
            change(item) {
                this.addNameField(item);
                this.$v.$touch()
            },
            status(validation) {
                return {
                    error: validation.$error,
                    dirty: validation.$dirty
                }
            },
            addNameField(item) {
                this.$store.commit('setFormField', { field: item.section + '.' + item['block-string-id'], value: this.field })
            },
        },
        validations() {
            let rules = {};

            if (this.fieldRequired) {
                rules = { ...rules, required }
            }

            if (this.fieldType === 'email') {
                rules = { ...rules, email }
            }

            if (this.fieldType === 'phone') {
                rules = { ...rules, minLength: minLength(12) }
            }

            return {
                field: rules
            }
        },
        computed: {
            inputField: {
                get: function() {
                    return this.field;
                },
                set: function(value) {
                    this.field = value;
                }
            },
            fieldType: function () {
                return this.items[0]['input-type'] || 'text'
            },
            fieldRequired: function () {
                if(this.items[0].required) {
                    return this.items[0].required
                }
            },
        },
        mounted() {
            this.$eventPool.$on("touchChildForm", () => {
                this.$v.$touch();

                this.$nextTick(() => this.$emit("returnStatusToParent", !this.$v.$invalid))
            })
        },
        destroyed() {
            this.$eventPool.$off("touchChildForm", () => {})
        }
    }
</script>

<style lang="scss">
    @import './resources/sass/app.scss';

</style>
