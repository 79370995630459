<template lang="pug">
    .field-wrap(
        @click="addNameSection(child, items)"
        v-if="typeChild !== 'text-with-image-and-inputs'"
        :class="["+
            "{'field-row': typeChild === 'row'}, " +
            "{'field-column': typeChild === 'column'}, " +
            "{'field-group': typeChild === 'group'}, " +
            "{'field-section': typeChild === 'section'}]")

        .field-item(v-for="(itemRow, index) in items"
            :class="{'image-item': itemRow.type === 'image'}")

            .field-title-section.h5(v-if="itemRow.type === 'section'") {{ itemRow.title }}

            .field-title-group(v-if="itemRow.type === 'fields-group'") {{ itemRow.title }}

            .field(v-if="itemRow.type === 'subtitle'")
                .sub-title.h5 {{ itemRow.subtitle }}

            .field(v-if="itemRow.type === 'text'")
                .text(v-html="itemRow.content")

            .field(v-if="itemRow.type === 'image' && itemRow.image")
                .image-list-wrap
                    .image-list
                        img(:src="itemRow.image.src" v-if="itemRow.image")

            .field(v-if="itemRow['input-type'] === 'text'"
                :class="{'icon-wrap': itemRow.icon}")
                .label(v-if="itemRow.title") {{ itemRow.title }}

                .input-wrap(:class="status($v.field)")
                    .icon(v-if="itemRow.icon")
                        img(:src="itemRow.icon.src")
                    input(type='text'
                        :name='itemRow.placeholder'
                        @change="change(itemRow)"
                        :class="status($v.field)"
                        v-model="inputField"
                        :placeholder="itemRow.placeholder").input

            .field(v-if="itemRow['input-type'] === 'phone'"
                :class="{'icon-wrap': itemRow.icon}")
                .label(v-if="itemRow.title") {{ itemRow.title }}

                .input-wrap(:class="status($v.field)")
                    .icon(v-if="itemRow.icon")
                        img(:src="itemRow.icon.src")
                    input(type='text'
                        :name='itemRow.placeholder'
                        @input="change(itemRow)"
                        @change="$v.field.$touch()"
                        :class="status($v.field)"
                        v-model="inputField"
                        v-mask="'### ### ####'"
                        :placeholder="itemRow.placeholder").input

            .field(v-if="itemRow.type === 'select' && itemRow.type !== 'text-with-image-and-inputs'"
                :class="{'icon-wrap': itemRow.icon}")
                .label(v-if="itemRow.title") {{ itemRow.title }}

                .input-wrap(:class="status($v.field)")
                    .icon(v-if="itemRow.icon")
                        img(:src="itemRow.icon.src")
                    v-select(v-model="inputField"
                        :options="itemRow.options"
                        @input="change(itemRow)"
                        @change="$v.field.$touch()"
                        :class="status($v.field)"
                        :placeholder="itemRow.placeholder"
                        autocomplete="disabled")

            .field(v-if="itemRow['input-type'] === 'number'"
                :class="{'icon-wrap': itemRow.icon}")
                .label(v-if="itemRow.title") {{ itemRow.title }}

                .input-wrap(:class="status($v.field)")
                    .icon(v-if="itemRow.icon")
                        img(:src="itemRow.icon.src")
                    input(type='number'
                        :name='itemRow.placeholder'
                        @change="change(itemRow)"
                        :class="status($v.field)"
                        v-model="inputField"
                        :placeholder="itemRow.placeholder").input

            .field(v-if="itemRow['input-type'] === 'email'"
                :class="{'icon-wrap': itemRow.icon}")
                .label(v-if="itemRow.title") {{ itemRow.title }}

                .input-wrap(:class="status($v.field)")
                    .icon(v-if="itemRow.icon")
                        img(:src="itemRow.icon.src")
                    input(type='text'
                        :name='itemRow.placeholder'
                        @change="change(itemRow)"
                        :class="status($v.field)"
                        v-model="inputField"
                        :placeholder="itemRow.placeholder").input

            .field(v-if="itemRow.type === 'textarea'"
                :class="{'icon-textarea-wrap': itemRow.icon}")
                .label(v-if="itemRow.title") {{ itemRow.title }}

                .input-wrap(:class="status($v.field)")
                    .icon(v-if="itemRow.icon")
                        img(:src="itemRow.icon.src")
                    textarea(:name='itemRow.placeholder'
                        @change="change(itemRow)"
                        @input="$v.field.$touch()"
                        :class="status($v.field)"
                        v-model="inputField"
                        :placeholder="itemRow.placeholder").textarea

            .gray-block(v-if="itemRow.type === 'text-with-image-and-inputs'")
                .wrap.left-block-wide
                    .gray-block--item
                        .text(v-if="itemRow.content" v-html="itemRow.content")
                        .image-mobile
                            img(:src="itemRow.image.src" v-if="itemRow.image")
                        .list
                            .wrap
                                fieldsBlockPalletSelect(v-if="itemRow.children && itemRow.children.length > 0"
                                    :inputFieldprops="inputField"
                                    :itemRowprops="itemRow"
                                    :items="itemRow.children")
                    .gray-block--item
                        .image
                            img(:src="itemRow.image.src" v-if="itemRow.image")

            fieldsBlockPallet(v-if="itemRow.children && itemRow.children.length > 0"
                :child="itemRow"
                :section="itemRow.type === 'section' ? itemRow.title : ''"
                :fieldsGroup="itemRow.type === 'fields-group' ? itemRow.title : ''"
                :typeChild="itemRow.type"
                :items="itemRow.children"
                @returnStatusToParent="formStatus =>$emit('returnStatusToParent', formStatus)")


</template>

<script>
    import fieldsBlockPalletSelect from './fieldsBlockPalletSelect.vue'
    import Vue from 'vue'
    import Vuex from 'vuex'
    import Vuelidate from 'vuelidate'
    import { required, email, minLength } from 'vuelidate/lib/validators'
    import VueScrollTo from 'vue-scrollto'
    import VueMask from 'v-mask'
    import vSelect from 'vue-select'
    import 'vue-select/dist/vue-select.css'

    const mapState = Vuex.mapState
    Vue.component("v-select", vSelect);
    Vue.use(Vuelidate);
    Vue.use(VueScrollTo);
    Vue.use(VueMask);

    var nameGroup = [];

    export default {
        name: "fieldsBlockPallet",
        props: [
            'items',
            'typeChild',
            'array',
            'child',
            'section',
            'fieldsGroup',
        ],
        components: {
            fieldsBlockPalletSelect,
        },
        data: () => {
            return {
                field: '',
                groupName: '',
            }
        },
        watch: {
            "$v.$invalid": function() {
                this.$emit("returnStatusToParent", !this.$v.$invalid);
            }
        },
        validations() {
            let rules = {}

            if (this.fieldRequired) {
                rules = { ...rules, required }
            }

            if (this.fieldType === 'email') {
                rules = { ...rules, email }
            }

            if (this.fieldType === 'phone') {
                rules = { ...rules, minLength: minLength(12) }
            }

            return {
                field: rules
            }
        },
        methods: {
            change(item) {
                this.addNameField(item)
                this.$v.$touch()
            },
            status(validation) {
                return {
                    error: validation.$error,
                    dirty: validation.$dirty
                }
            },
            addNameSection(child, items) {
                var arr = [];

                if(items[0].subtitle !== undefined && items[0].type === 'subtitle' && items[0].subtitle !== '') {
                    this.$store.state.groupName = items[0].subtitle + ': ';
                }

                if(items[0].title !== undefined && items[0].type === 'fields-group' && items[0].title !== '') {
                    this.$store.state.fieldGroupName = items[0].title + ': ';
                }

                if(child !== undefined && child.type === 'section' && !this.$store.state.blockPallet[child.title ? child.title : child.placeholder]) {
                    this.$store.state.blockPallet[child.title ? child.title : child.placeholder] = arr;
                }

                if(child !== undefined && child.type === 'section') {
                    this.$store.state.sectionName = child.title ? child.title : child.placeholder;
                }
            },
            addNameField: function (item) {
                if(this.$store.state.groupName !== '' && this.$store.state.groupName !== null) {
                    this.$store.commit('setFormField', { field: item.section + '.' + this.$store.state.groupName + item['block-string-id'], value: this.field })
                } else {
                    this.$store.commit('setFormField', { field: item.section + '.' + '.' + item['block-string-id'], value: this.field })
                }
            },
            backContactInformationShow() {
                this.$store.state.blockPalletActive = false
                this.$store.state.stringerPalletActive = false
                this.$store.state.contactInformationActive = true
            },
        },
        computed: {
            inputField: {
                get: function() {
                    this.$store.state.groupName = null
                    this.$store.state.fieldGroupName = null
                    return this.field;
                },
                set: function(value) {
                    this.field = value;
                }
            },
            fieldType: function () {
                return this.items[0]['input-type'] || 'text'
            },
            fieldRequired: function () {
                if(this.items[0].required) {
                    return this.items[0].required
                }
            },
        },
        mounted() {
            this.addNameField(this.items[0])

            this.$eventPool.$on("touchChildForm", () => {
                this.$v.$touch()
            });
        },
        destroyed() {
            this.$eventPool.$off("touchChildForm", () => {})
        },
        created() {

        },
    }
</script>

<style lang="scss">
    @import './resources/sass/app.scss';

</style>
