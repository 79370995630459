<template>
    <div class="wrap">
        <input :type="formtypeprops" hidden>

        <div class="form__title-wrap">
            <h3 class="form__title"
                v-for="itemChildren in formsItem"
                v-if="itemChildren.type === 'title'">{{itemChildren.title}}</h3>
            <div class="back-link" @click="backContactInformationShow">
                <div class="icon icon-arrow-longer"></div>
                <div class="text">Contact information</div>
            </div>
        </div>

<!--        <pre>{{formsItem}}</pre>-->

        <fieldsBlockPallet :array="$store.state.blockPallet"
                           @returnStatusToParent="formStatus =>childIsValid=formStatus"
                           :items="formsItem"></fieldsBlockPallet>

        <div class="button-wrap">
            <button type="button" class="btn" @click="submit">Submit request</button>
        </div>

        <div class="info-text" v-if="submitStatus === 'ERROR'">
            <div class="error-wrap" v-if="submitStatus === 'ERROR'">
                <div class="icon-cross close" @click="submitStatus = null"></div>
                Some required fields are missing. Please fix them.
            </div>
        </div>

        <div class="info-text" v-if="submitStatus === 'OK'">
            <div class="success" v-if="submitStatus === 'OK'">
                <div class="icon-cross close" @click="submitStatus = null"></div>
                Thanks for your submission!
            </div>
        </div>

        <div class="info-text" v-if="submitStatus === 'PENDING'">
            <div class="typo__p" v-if="submitStatus === 'PENDING'">Sending...</div>
        </div>

        <vue-recaptcha :sitekey="recapthcakeyprops"
                       data-badge="inline"
                       ref="recaptcha"
                       size="invisible"
                       @verify="onVerify"></vue-recaptcha>
    </div>
</template>

<script>
    import fieldsBlockPallet from "./fieldsBlockPallet.vue";
    import Vue from 'vue'
    import Vuex from 'vuex';
    import Vuelidate from 'vuelidate'
    import { required, email } from 'vuelidate/lib/validators'
    import VueRecaptcha from 'vue-recaptcha';
    import VueScrollTo from 'vue-scrollto'
    import VueMask from 'v-mask'
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";
    Vue.component("v-select", vSelect);

    Vue.use(Vuex);
    Vue.use(VueMask);
    Vue.use(Vuelidate);
    Vue.use(VueScrollTo);

    export default {
        name: 'BlockPallet',
        components: {
            'vue-recaptcha': VueRecaptcha,
            fieldsBlockPallet,
        },
        props: [
            'formtypeprops',
            'recapthcakeyprops',
            'actions',
            'formsItem',
        ],
        data: () => {
            return {
                captcha: '',
                captchaActive: false,
                childIsValid: false,
                submitStatus: null,
            }
        },
        validations() {

        },
        methods: {
            submit() {
                this.$eventPool.$emit("touchChildForm")

                this.$nextTick(() => {
                    this.submitStatus = 'PENDING';

                    if (this.childIsValid) {
                        this.$refs.recaptcha.execute();
                    } else {
                        this.submitStatus = 'ERROR';
                        setTimeout(() => {
                            VueScrollTo.scrollTo('.error', 300);
                        }, 200);
                    }
                })
            },
            onVerify: function (response) {
                let formData = new FormData();
                formData.append('g-recaptcha-response', response);
                formData.append('form_type', 'block-pallet');

                const currentForm = this.$store.state.currentForm
                Object.keys(currentForm).forEach((item) => {
                    if (typeof currentForm[item] === 'object') {
                        formData.append(item, JSON.stringify(currentForm[item]))
                    } else {
                        formData.append(item, currentForm[item])
                    }
                })

                axios.post(this.actions, formData)
                    .then(response => {
                        console.log('message', response);

                        if (response.data.result === 'ok') {
                            this.submitStatus = 'OK';
                         
                        }

                        if (response.data.result === 'error') {
                            this.submitStatus = 'ERROR';
                        }

                        this.$refs.recaptcha.reset();
                    })
                    .catch(error => {
                        console.log(error)
                        this.$refs.recaptcha.reset();
                    });
            },
            status(validation) {
                return {
                    error: validation.$error,
                    dirty: validation.$dirty
                }
            },
            backContactInformationShow() {
                this.$store.state.blockPalletActive = false
                this.$store.state.stringerPalletActive = false
                this.$store.state.contactInformationActive = true
            },
        },
        mounted() {
            VueScrollTo.scrollTo('.GetAQuoteForm', 300);
        }
    }
</script>

<style scoped lang="scss">
    @import './resources/sass/var.scss';

    .field {

        &-row {
            margin-left: -100px;

            .field {
                margin-left: 100px;
            }
        }
    }

</style>
