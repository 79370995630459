<template>
    <div class="form">
        <div class="wrap">
            <form @submit.prevent="submit" class="contact-information">
                <input :type="formtype" hidden>

                <fields :items="forms.contacts"
                        @returnStatusToParent="formStatus => formIsValid=formIsValid&&formStatus"
                        :array="$store.state.contactInformation"
                        :key="contactInformationKey"
                        ></fields>

                <fields :items="forms['requested-service']"
                        @returnStatusToParent="formStatus => formIsValid=formIsValid&&formStatus"
                        :array="$store.state.contactInformation"
                        :key="contactInformationKey"
                        ></fields>

                <div class="Form-Modal-wrap Info-Modal"
                     v-if="$store.state.modalId === 'success'"
                     :class="{'active': $store.state.modalId === 'success'}">
                    <div class="overflow" @click="closeModal()"></div>

                    <div class="wrap">
                        <div class="info-message">
                            <div class="icon icon-success"></div>
                            <div class="title">Success</div>
                            <div class="text">You request has been created! We will contact you shortly!</div>
                            <div class="btn" @click="closeModal()">Ok</div>
                        </div>
                    </div>
                </div>

                <div class="button-wrap">
                    <button type="submit"
                            class="btn"
                            :class="{'disable': $store.state.submitStatus === 'PENDING'}">
                        Send
                    </button>
                </div>

                <div class="info-text"
                     v-if="$store.state.submitStatus === 'ERROR' || $store.state.submitStatus === 'OK' || $store.state.submitStatus === 'PENDING'">
                    <div class="error-wrap" v-if="$store.state.submitStatus === 'ERROR'">
                        <div class="icon-cross close" @click="$store.state.submitStatus = null"></div>
                        Some required fields are missing. Please fix them.
                    </div>
                    <div class="typo__p" v-if="$store.state.submitStatus === 'PENDING'">Sending...</div>
                </div>
            </form>

            <vue-recaptcha :sitekey="recapthcakey"
                           data-badge="inline"
                           ref="recaptcha"
                           size="invisible"
                           @verify="onVerify"></vue-recaptcha>
        </div>
    </div>
</template>

<script>
import fields from "./fields.vue";
import Vue from 'vue'
import Vuex from 'vuex';
import Vuelidate from 'vuelidate'
import {required, email} from 'vuelidate/lib/validators'
import VueRecaptcha from 'vue-recaptcha';
import VueScrollTo from 'vue-scrollto'
import VueMask from 'v-mask'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

Vue.component("v-select", vSelect);

Vue.use(VueMask);
Vue.use(Vuelidate);
Vue.use(VueScrollTo);

var body = document.querySelector('body');

export default {
    name: 'GetAQuote',
    components: {
        'vue-recaptcha': VueRecaptcha,
        fields,
    },
    props: [
        'formtype',
        'recapthcakey',
        'actionprops',
        'forms',
    ],
    data() {
        return {
            captcha: '',
            formIsValid: true,
            fileName: '',
            file: [],
            contactInformationKey: 0,
            requestedServiceKey: 1,
        }
    },
    validations() {
    },
    methods: {
        onFileChange(el) {
            // eslint-disable-next-line no-unused-vars
            const files = el.target.files || el.dataTransfer.files;
            // eslint-disable-next-line prefer-destructuring
            this.fileName = document.getElementById('upload').files[0].name;
            this.file.push(document.getElementById('upload').files[0])
        },
        submit() {
            this.formIsValid = true;
            this.$eventPool.$emit("touchChildForm");

            this.$nextTick(() => {
                if (this.formIsValid) {
                    this.$refs.recaptcha.execute();
                } else {
                    this.$store.state.submitStatus = 'ERROR';
                    setTimeout(() => {
                        VueScrollTo.scrollTo('.error', 300);
                    }, 200);
                }
            })
        },
       forceRerender() {
            this.contactInformationKey += 1;
            this.requestedServiceKey += 1;
        },
        onVerify: function (response) {
            this.$store.state.submitStatus = 'PENDING';

            let formData = new FormData();
            formData.append('g-recaptcha-response', response);
            formData.append('form_type', 'get-a-quote');

            if (this.file[0]) {
                formData.append('file', this.file[0]);
            }

            const currentForm = this.$store.state.currentForm;

            Object.keys(currentForm).forEach((item) => {
                if(Object.keys(currentForm[item])[0] !== 'undefined') {
                    if (typeof currentForm[item] === 'object') {
                        formData.append(item, JSON.stringify(currentForm[item]))
                    } else {
                        formData.append(item, currentForm[item])
                    }
                }
            });

            axios.post(this.actionprops, formData)
                .then(response => {
                    if (response.data.result === 'ok') {
                        this.$store.state.submitStatus = 'OK';
                        this.$store.state.modalId = 'success';
                        this.$store.state.currentForm = {};
                        body.classList.add('noscroll');

                        console.log('gtag get-a-quote');
                          gtag('event', 'submit', {
                             'event_category': 'form',
                              'event_label': 'request-quote',
                              'value': 1
                           });

                      this.forceRerender();
                      // document.getElementsByClassName('contact-information')[0].reset();
                    }

                    if (response.data.result === 'error') {
                        this.$store.state.submitStatus = 'ERROR';
                        this.$store.state.modalId = '';
                    }

                    this.$refs.recaptcha.reset();
                })
                .catch(error => {
                    console.log(error);
                    this.$refs.recaptcha.reset();
                });

        },
        status(validation) {
            return {
                error: validation.$error,
                dirty: validation.$dirty
            }
        },
        closeModal() {
            this.$store.state.modalId = '';
            body.classList.remove('noscroll');
        },
    },
}
</script>

<style scoped lang="scss">
@import './resources/sass/var.scss';

</style>
